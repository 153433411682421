import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer-container'>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <Link to='/' className='social-logo'>
                        Tæknimenn
                        </Link>
                    </div>
                    <small class='website-rights'>Tæknimenn © 2023</small>
                    <div class='social-icons'>
                        <Link
                            class='social-icon-link facebook'
                            to='https://www.facebook.com/Taeknimenn'
                            target='_blank'
                            aria-label='Facebook'
                        >
                            <i class='fab fa-facebook-f' />
                        </Link>
                        <Link
                            class='social-icon-link instagram'
                            to='https://www.instagram.com/taeknimenn/'
                            target='_blank'
                            aria-label='Instagram'
                        >
                            <i class='fab fa-instagram' />
                        </Link>
                        <Link
                            class='social-icon-link instagram'
                            to='tel:'
                            aria-label='Phone'
                        >
                            <i class="fa-sharp fa-solid fa-phone fa-sm"/>
                        </Link>
                        <Link
                            class='social-icon-link instagram'
                            to='mailto:TaeknimennAdstod@gmail.com'
                            aria-label='eMail'
                        >
                            <i class="fa-sharp fa-solid fa-envelope fa-sm"/>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
