import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Footer from './components/Footer';
import Order from './components/pages/Order'
import Admin from './components/pages/Admin';
import Prices from './components/pages/Prices';
import Contact from './components/pages/Contact';

var upState = false;

function App() {
  return (
    <>
      <Router>
        <div className='page-container'>
          <div className='content-wrap'>
            <Navbar/>
            <Routes>
              <Route path='/' exact Component={Home} />
              <Route path='/order' exact Component={Order} />
              <Route path='/pricing' exact Component={Prices} />
              <Route path='/contact' exact Component={Contact} />
              <Route path='/admin' exact Component={Admin} />
            </Routes>
          </div>
          <Footer className='footer-obj' />
        </div>
      </Router>
    </>
  );
}

export default App;
export {upState};