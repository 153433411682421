import React from 'react';
import { Link } from 'react-router-dom';
import "./ContactInfo.css";

import { upState } from '../App';

function ContactInfo() {
    if(upState){
        return (
            <div>
                <div className='contact_box'>
                    <Link
                        className='contact_el'
                        to='tel:8218139'
                        aria-label='Phone'
                    >
                        <i class="fa-sharp fa-solid fa-phone fa-sm contact_icon"/>
                        <p className='contact_ting'>821-8139</p>
                    </Link>
                    <Link
                        className='contact_el'
                        to='mailto:TaeknimennAdstod@gmail.com'
                        aria-label='eMail'
                    >
                        <i class="fa-sharp fa-solid fa-envelope fa-sm contact_icon"/>
                        {window.innerWidth>768 && 
                            <p className='contact_ting'>TaeknimennAdstod@gmail.com</p>
                        }
                        {window.innerWidth<768 && 
                            <p className='contact_ting_xs'>TaeknimennAdstod@gmail.com</p>
                        }
                    </Link>
                    <Link
                        className='contact_el'
                        to='https://www.facebook.com/Taeknimenn'
                        target='_blank'
                        aria-label='Facebook'
                    >
                        <i class="fab fa-facebook-f contact_icon"/>
                        {window.innerWidth>768 && 
                            <p className='contact_ting'>Facebook.com/Taeknimenn</p>
                        }
                        {window.innerWidth<768 && 
                            <p className='contact_ting'>Taeknimenn</p>
                        }
                    </Link>
                    <Link
                        className='contact_el'
                        to='https://www.instagram.com/taeknimenn/'
                        target='_blank'
                        aria-label='Instagram'
                    >
                        <i class="fab fa-instagram contact_icon"/>
                        {window.innerWidth>768 && 
                            <p className='contact_ting'>Instagram.com/Taeknimenn</p>
                        }
                        {window.innerWidth<768 && 
                            <p className='contact_ting'>Taeknimenn</p>
                        }
                    </Link>
                </div>
            </div>
        )
    }else{
        return (
            <div>
                <div className='contact_box'>
                    <h2>Tæknimenn hafa tekið sér pásu. <br/>Endurkomudagur er ekki ákveðinn.</h2>
                </div>
            </div>
        )
    }
}

export default ContactInfo
